import { List } from "immutable";
import {
  ADDRESS_BOOK_URL,
  BANK_LIST_URL,
  COMMUNICATION_SETTINGS_LIST_URL,
  FINANCE_ORDER_LIST_URL,
  FINANCE_TRANSACTIONS_URL,
  FINANCE_WITHDRAW_LIST_URL,
  ORDER_LIST_URL,
  ORDER_UPLOAD_V2_URL,
  ORDERS_PER_POSTCODES_URL,
  RECEIVING_ORDERS_URL,
} from "./CustomerPathConstants";

// export const DASHBOARD = {
//   ind: 1,
//   group: "dashboard",
//   slug: "dashboard",
//   icon: "actionDashboard",
//   url: CHARTS_URL,
// };
export const DOMESTIC_ORDERS = {
  ind: 2,
  group: "orders",
  slug: "domestic_orders",
  icon: "truckDelivery",
  url: ORDER_LIST_URL,
  params: { is_uae: true },
};
export const INTERNATIONAL_ORDERS = {
  ind: 2,
  group: "orders",
  slug: "international_orders",
  icon: "actionFlightTakeoff",
  url: ORDER_LIST_URL,
};
export const RECEIVING_ORDERS = {
  ind: 2,
  group: "orders",
  slug: "receiving_orders",
  icon: "communicationCallReceived",
  url: RECEIVING_ORDERS_URL,
};
// export const CREATE_ORDER = {
//   ind: 2,
//   group: "orders",
//   slug: "create_order",
//   icon: "contentAddBox",
//   url: ORDER_CREATE_URL,
// };
// export const UPLOAD_ORDERS = {
//   ind: 2,
//   group: "orders",
//   slug: "upload_orders",
//   icon: "fileFileUpload",
//   url: ORDER_UPLOAD_URL,
// };
export const UPLOAD_ORDERS_V2 = {
  ind: 2,
  group: "orders",
  slug: "new_upload_orders",
  icon: "fileFileUpload",
  url: ORDER_UPLOAD_V2_URL,
};
export const POSTCODES_COORDINATES = {
  ind: 2,
  group: "orders",
  slug: "coordinates_of_postcodes",
  icon: "communicationLocationOn",
  url: ORDERS_PER_POSTCODES_URL,
};
export const FINANCE_ORDERS = {
  ind: 3,
  group: "finance",
  slug: "orders",
  icon: "editorMonetizationOn",
  // icon: "actionAccountBalance",
  url: FINANCE_ORDER_LIST_URL,
};
export const FINANCE_WITHDRAW = {
  ind: 3,
  group: "finance",
  slug: "withdraw_requests",
  icon: "actionAssignmentReturned",
  url: FINANCE_WITHDRAW_LIST_URL,
};
export const FINANCE_TRANSACTIONS = {
  ind: 3,
  group: "finance",
  slug: "transactions",
  icon: "actionSwapHoriz",
  url: FINANCE_TRANSACTIONS_URL,
};
export const BANKS = {
  ind: 3,
  group: "finance",
  slug: "banks",
  icon: "actionAccountBalance",
  url: BANK_LIST_URL,
};
export const COMMUNICATION_SETTINGS = {
  ind: 4,
  group: "settings",
  slug: "communication",
  icon: "actionSettings",
  // icon: "communicationIcon",
  url: COMMUNICATION_SETTINGS_LIST_URL,
};
export const ADDRESS_BOOK = {
  ind: 5,
  group: "other",
  slug: "address_book",
  icon: "addressIcon",
  url: ADDRESS_BOOK_URL,
};

export default List([
  DOMESTIC_ORDERS,
  INTERNATIONAL_ORDERS,
  RECEIVING_ORDERS,
  // CREATE_ORDER,
  // UPLOAD_ORDERS,
  UPLOAD_ORDERS_V2,
  POSTCODES_COORDINATES,
  // FINANCE_ORDERS,
  // FINANCE_WITHDRAW,
  // FINANCE_TRANSACTIONS,
  // BANKS,
  // COMMUNICATION_SETTINGS,
  // ADDRESS_BOOK,
]);
